import React from 'react'
import './stylesheets/Signup.scss'
import {Input, SelectPicker, Divider, DatePicker, Button, Checkbox, InputNumber} from 'rsuite'
import {toast} from 'react-toastify'
import axios from 'axios'
import {CheckPicker} from 'rsuite'

class Signup extends React.Component{

    state = {

        facultatea : null,
        facultatea2 : null,
        ambeleFac : false,
        optionList : [],
        optionList2 : [],
        rural : false,
        casatorie : "",
        social: false,
        rrom: false,
        dizabilitati: false,
        sesiunea : "",
        proba: "",
        initiala : "",
        localitatea : "",
        cetatenie : "",
        domiciliu : "",
        telefonFix : "",
        telefonMobil :"",
        medieBAC : "",
        medieEdFizica : "",
        medieBACRO : "",
        psiho : true,
        studii_univ : "",
        declar : false,
        confirm : false,
        gdpr : false,
        submitedInfo : "",
        detalii_studii_univ : "",    

    }

    componentDidMount = async () => {

        let submitedInfo = await axios.get('/api/submission/getInfo/' +  localStorage.getItem('id'))
        console.log(submitedInfo)
        if ( submitedInfo.data !== "" ){
            this.setState({
                submitedInfo : submitedInfo.data,
                facultatea : submitedInfo.data.facultatea,
                facultatea2 : submitedInfo.data.facultatea2,
                ambeleFac :submitedInfo.data.ambeleFac,
                optionList : submitedInfo.data.optionList,
                optionList2 : submitedInfo.data.optionList2,
                rural : submitedInfo.data.rural,
                casatorie : submitedInfo.data.casatorie,
                social: submitedInfo.data.social,
                rrom: submitedInfo.data.rrom,
                dizabilitati: submitedInfo.data.dizabilitati,
                sesiunea : submitedInfo.data.sesiunea,
                proba: submitedInfo.data.proba,
                initiala : submitedInfo.data.initiala,
                localitatea : submitedInfo.data.localitatea,
                cetatenie : submitedInfo.data.cetatenie,
                domiciliu : submitedInfo.data.domiciliu,
                telefonFix : submitedInfo.data.telefonFix,
                telefonMobil :submitedInfo.data.telefonMobil,
                medieBAC : submitedInfo.data.medieBAC,
                medieEdFizica : submitedInfo.data.medieEdFizica,
                medieBACRO : submitedInfo.data.medieBACRO,
                psiho : submitedInfo.data.psiho,
                studii_univ : submitedInfo.data.studii_univ,
                detalii_studii_univ : submitedInfo.data.detalii_studii_univ,
                comisie : submitedInfo.data.comisie,
                declar : submitedInfo.data.declar,
                confirm : submitedInfo.data.confirm,
                gdpr : submitedInfo.data.gdpr,
            })
        }
        
    }

    handleChange = (value, event) => {
    

        this.setState({
            [event.target.name] : value
        })
    }

    handleSelectFacultate = (value) => {

        // if ( this.state.facultatea !== null) document.getElementsByClassName("rs-picker-toggle-clean")[1].click()

        this.setState({
            facultatea : value,
            optionList : []
        })
    }

    handleSelectSesiune = (value) => {

        this.setState({
            sesiunea : value
        })
    }
    handleSelectProba = (value) => {

        console.log(value);
        this.setState({
            proba : value
        })
    }

    sendData = () => {


        if ( this.state.studii_univ !== "Nu am mai fost inscris la alta facultate" && this.state.detalii_studii_univ !== "" && this.state.detalii_studii_univ === ""  ){
            toast.error("Nu ati completat detalii despre studiile universitare")
            return;
        }

        console.log(this.state)
        if ( this.state.telefonMobil.length !== 10 ){
            toast.error("Numarul de telefon nu este corect scris")
            return;
        }

        if ( this.state.ambeleFac === true && this.state.facultatea2 === "" ){
            toast.error("Nu ati selectat a doua facultate")
            return;
        }
        if ( this.state.facultatea2 !== ""){
            let optiuni =  this.state.optionList.map (element => {
                return element.split(" - ")[0]
            })

            const func = (element) => element.includes("Educatie Fizica si Sportiva") ||element.includes("Sport si Performanta Motrica") || element.includes("Management in Sport")


            if ( this.state.facultatea2 === "Educatie Fizica si Sport" && optiuni.some(func) === false){
                toast.error("Nu ati selectat optiuni de la EFS")
                return;
            }
            else
            if ( this.state.facultatea2 === "Kinetoterapie" && optiuni.includes("Kinetoterapie si motricitate speciala") === false){
                toast.error("Nu ati selectat optiuni de la Kinetoterapie")
                return;
            }
        }


        if ( this.state.declar === false || this.state.gdpr === false ){
            toast.error("Nu ati bifat casutele de termeni si conditii si declaratie pe proprie raspundere")
            return;
        }

        if ( this.state.facultatea === "" || this.state.sesiunea === "" || this.state.initiala === "" ||
        this.state.localitatea === "" || this.state.domiciliu === "" || this.state.cetatenie === "" 
        || this.state.telefonMobil === "" || this.state.optionList.length === 0 || ((this.state.optionList.includes("Educatie Fizica si Sportiva") === true || this.state.optionList.includes("Sport si Performanta Motrica") === true) && this.state.proba === "" )){

            toast.error("Nu ati completat toate campurile");
            return;
        }

        
        let obj = {
            userID : localStorage.getItem('id'),
            facultatea : this.state.facultatea,
            facultatea2 : this.state.facultatea2,
            ambeleFac : this.state.ambeleFac,
            optionList : this.state.optionList,
            optionList2 : this.state.optionList2,
            casatorie : this.state.casatorie,
            rural : this.state.rural,
            social : this.state.social,
            rrom : this.state.rrom,
            dizabilitati : this.state.dizabilitati,
            sesiunea : this.state.sesiunea,
            proba: this.state.proba,
            initiala : this.state.initiala,
            localitatea : this.state.localitatea,
            domiciliu : this.state.domiciliu,
            cetatenie : this.state.cetatenie,
            telefonFix : this.state.telefonMobil,
            telefonMobil : this.state.telefonMobil,
            medieBAC : this.state.medieBAC.toString(),
            medieEdFizica : this.state.medieEdFizica.toString(),
            medieBACRO : this.state.medieBACRO.toString(),
            studii_univ : this.state.studii_univ,
            psiho : this.state.psiho,
            detalii_studii_univ : this.state.detalii_studii_univ,
            type : "Licenta"
        }
        // console.log(obj);

        if ( this.state.submitedInfo !== "" ){
            axios.post('/api/submission/editInfo/' + localStorage.getItem('id'), obj)
            .then( response => {
    
                toast.success("Informatii salvate cu succes!")
                this.props.moveToDocuments();
                window.location.reload();
                console.log(response);
            })
            .catch ( err => {
                toast.error("S-a produs o eroare")
            })
        }
        else{
            axios.post('/api/submission/submitInfo/' + localStorage.getItem('id'), obj)
            .then( response => {
    
                toast.success("Informatii salvate cu succes!")
                this.props.moveToDocuments();
                window.location.reload();
                console.log(response);
            })
            .catch ( err => {
                toast.error("S-a produs o eroare")
            })
        }
        // axios.post('https://efs.admitere-unefs.ro/api/submission/submitInfo/' + localStorage.getItem('id'), obj)
       
    }

    selectOption = (value, item) => {

        console.log(item);
        let options = this.state.optionList
        let selectedItem = item.value;

        if ( options.includes(selectedItem) === true ){

            let indexEl = options.indexOf(selectedItem);
            if (indexEl > -1) {
                options.splice(indexEl, 1);

                this.setState({
                    optionList : options
                }, () => {
                    console.log(options)
                })
            }
            
        }
        else{
            options.push(selectedItem);
            this.setState({
                optionList : options
            }, () => {
                console.log(options)
            })
        }

       
    }


    clearOptions = () => {


            this.setState({
                optionList : []
            }, () => {
                alert("da");
            })
    }

    selectOption2 = (value, item) => {

        console.log(item);
        let options = this.state.optionList
        options.push(value);
            this.setState({
                optionList : options[options.length - 1]
            }, () => {
                console.log(options)
            })
    }

    selectAmbele = (value, checked) => {

        this.setState({
            ambeleFac : checked
        })
    }
    render(){

        let options = this.state.optionList.map( (e, index) => {

            return <p> {index + 1}. {e}</p>
        })

        // let options2 = this.state.optionList2.map( (e, index) => {

        //     return <p> {index + 1}. {e}</p>
        // })
        
        let data = []
        let data2 = []

        if ( this.state.facultatea === "Kinetoterapie"){
           data = [
            //    {
            //     "label": "Kinetoterapie si motricitate speciala ",
            //     "value": "Kinetoterapie si motricitate speciala ",
            //     "role": "Master"
            // },
            {
                "label": "Kinetoterapie si motricitate speciala",
                "value": "Kinetoterapie si motricitate speciala",
                "role": "Master"
            }]

            if ( this.state.rrom === true ){
                data = []
            }

            if ( this.state.rural === true ){
                data = [
                    {
                    "label": "Kinetoterapie si motricitate speciala ",
                    "value": "Kinetoterapie si motricitate speciala ",
                    "role": "Master"
                }
            ]
            }
            if ( this.state.dizabilitati === true ){
                data = [
                    {
                    "label": "Kinetoterapie si motricitate speciala ",
                    "value": "Kinetoterapie si motricitate speciala ",
                    "role": "Master"
                }
            ]
            }
            if ( this.state.social === true ){
                data = [
                    {
                    "label": "Kinetoterapie si motricitate speciala ",
                    "value": "Kinetoterapie si motricitate speciala ",
                    "role": "Master"
                }
            ]
            }
        }

        if ( this.state.facultatea2 === "Kinetoterapie"){
            data2 = [
            //     {
            //      "label": "Kinetoterapie si motricitate speciala ",
            //      "value": "Kinetoterapie si motricitate speciala ",
            //      "role": "Master"
            //  },
             {
                 "label": "Kinetoterapie si motricitate speciala",
                 "value": "Kinetoterapie si motricitate speciala",
                 "role": "Master"
             }]

             if ( this.state.rrom === true ){
                data2 = []
            }
            if ( this.state.rural === true ){
                data2 = [
                    {
                    "label": "Kinetoterapie si motricitate speciala ",
                    "value": "Kinetoterapie si motricitate speciala ",
                    "role": "Master"
                }
            ]
            }
            if ( this.state.social === true ){
                data2 = [
                    {
                    "label": "Kinetoterapie si motricitate speciala ",
                    "value": "Kinetoterapie si motricitate speciala ",
                    "role": "Master"
                }
            ]
            }
         }

        if ( this.state.facultatea === "Educatie Fizica si Sport"){

            data = [
                
                // {
                //     "label": "Educatie Fizica si Sportiva ",
                //     "value": "Educatie Fizica si Sportiva ",
                //     "role": "Master"
                // },
                // {
                //     "label": "Sport si Performanta Motrica ",
                //     "value": "Sport si Performanta Motrica ",
                //     "role": "Master"
                // },
                // {
                //     "label": "Management in Sport ",
                //     "value": "Management in Sport ",
                //     "role": "Master"
                // },
                
               
                // {
                //     "label": "Educatie Fizica si Sportiva",
                //     "value": "Educatie Fizica si Sportiva",
                //     "role": "Master"
                // },
               
                // {
                //     "label": "Sport si Performanta Motrica",
                //     "value": "Sport si Performanta Motrica",
                //     "role": "Master"
                // },
                
                // {
                //     "label": "Management in Sport",
                //     "value": "Management in Sport",
                //     "role": "Master"
                // },
                {
                    "label": "Sport si Performanta Motrica - IFR",
                    "value": "Sport si Performanta Motrica - IFR",
                    "role": "Master"
                },
                {
                    "label": "Educatie Fizica si Sportiva - IFR",
                    "value": "Educatie Fizica si Sportiva - IFR",
                    "role": "Master"
                },
                // {
                //     "label": "Sport si Performanta Motrica - Sportivi de performanta - fara examen",
                //     "value": "Sport si Performanta Motrica - Sportivi de performanta - fara examen",
                //     "role": "Master"
                // }
               
                // {
                //     "label": "Educatie Fizica si Sportiva - Franceza",
                //     "value": "Educatie Fizica si Sportiva - Franceza",
                //     "role": "Master"
                // },
                // {
                //     "label": "Sport si Performanta Motrica - Engleza",
                //     "value": "Sport si Performanta Motrica - Engleza",
                //     "role": "Master"
                // },
               
                
            ]

            if ( this.state.rrom === true){
                data = [
                
                    {
                        "label": "Educatie Fizica si Sportiva",
                        "value": "Educatie Fizica si Sportiva",
                        "role": "Master"
                    },
                    // {
                    //     "label": "Sport si Performanta Motrica",
                    //     "value": "Sport si Performanta Motrica",
                    //     "role": "Master"
                    // },
                    // {
                    //     "label": "Management in Sport ",
                    //     "value": "Management in Sport ",
                    //     "role": "Master"
                    // }
                    
                ]

            }
            if ( this.state.dizabilitati === true){
                data = [
                
                    {
                        "label": "Educatie Fizica si Sportiva",
                        "value": "Educatie Fizica si Sportiva",
                        "role": "Master"
                    },
                    {
                        "label": "Sport si Performanta Motrica",
                        "value": "Sport si Performanta Motrica",
                        "role": "Master"
                    },
                    {
                        "label": "Management in Sport",
                        "value": "Management in Sport",
                        "role": "Master"
                    }
                    
                ]

            }

            if ( this.state.rural === true ){

                data = [
                
                    {
                        "label": "Educatie Fizica si Sportiva",
                        "value": "Educatie Fizica si Sportiva",
                        "role": "Master"
                    },
                    {
                        "label": "Sport si Performanta Motrica",
                        "value": "Sport si Performanta Motrica",
                        "role": "Master"
                    },
                    {
                        "label": "Management in Sport ",
                        "value": "Management in Sport ",
                        "role": "Master"
                    }
                    
                ]
            }
            if ( this.state.social === true ){
                 data = [
                
                    {
                        "label": "Educatie Fizica si Sportiva",
                        "value": "Educatie Fizica si Sportiva",
                        "role": "Master"
                    },
                    {
                        "label": "Sport si Performanta Motrica",
                        "value": "Sport si Performanta Motrica",
                        "role": "Master"
                    },
                    {
                        "label": "Management in Sport ",
                        "value": "Management in Sport ",
                        "role": "Master"
                    }
                    
                ]
            }
        }

        if ( this.state.facultatea2 === "Educatie Fizica si Sport"){
            data2 = [
                
                // {
                //     "label": "Educatie Fizica si Sportiva ",
                //     "value": "Educatie Fizica si Sportiva ",
                //     "role": "Master"
                // },
                // {
                //     "label": "Sport si Performanta Motrica ",
                //     "value": "Sport si Performanta Motrica ",
                //     "role": "Master"
                // },
                // {
                //     "label": "Management in Sport ",
                //     "value": "Management in Sport ",
                //     "role": "Master"
                // },
                {
                    "label": "Sport si Performanta Motrica - IFR",
                    "value": "Sport si Performanta Motrica - IFR",
                    "role": "Master"
                },
            //    {
            //         "label": "Sport si Performanta Motrica - Sportivi de performanta - fara examen",
            //         "value": "Sport si Performanta Motrica - Sportivi de performanta - fara examen",
            //         "role": "Master"
            //     }
                // {
                //     "label": "Educatie Fizica si Sportiva",
                //     "value": "Educatie Fizica si Sportiva",
                //     "role": "Master"
                // },
                // {
                //     "label": "Sport si Performanta Motrica",
                //     "value": "Sport si Performanta Motrica",
                //     "role": "Master"
                // },
                // {
                //     "label": "Management in Sport",
                //     "value": "Management in Sport",
                //     "role": "Master"
                // },
               
                
            ]

            if ( this.state.rrom === true){
                data2 = [
                
                    {
                        "label": "Educatie Fizica si Sportiva",
                        "value": "Educatie Fizica si Sportiva",
                        "role": "Master"
                    }
                ]

            }
            if ( this.state.rural === true ){

                data2 = [
                
                    {
                        "label": "Educatie Fizica si Sportiva",
                        "value": "Educatie Fizica si Sportiva",
                        "role": "Master"
                    },
                    {
                        "label": "Sport si Performanta Motrica ",
                        "value": "Sport si Performanta Motrica ",
                        "role": "Master"
                    },
                    {
                        "label": "Management in Sport ",
                        "value": "Management in Sport ",
                        "role": "Master"
                    },
                   
                    
                ]
            }

            if ( this.state.social === true ){
                data2 = [
               
                   {
                       "label": "Educatie Fizica si Sportiva",
                       "value": "Educatie Fizica si Sportiva",
                       "role": "Master"
                   },
                   {
                       "label": "Sport si Performanta Motrica",
                       "value": "Sport si Performanta Motrica",
                       "role": "Master"
                   },
                   {
                       "label": "Management in Sport ",
                       "value": "Management in Sport ",
                       "role": "Master"
                   },
                   
               ]
           }
        }

       
        let title = [{label: "Kinetoterapie", value : "Kinetoterapie"}]
        if ( window.location.href === "https://efs.admitere-unefs.ro/dashboardStudent" )
            title = [{label: "Educatie Fizica si Sport", value : "Educatie Fizica si Sport"}]

        
        return(

            <div className = "Signup-container">
                <div className = "Signup-content">
                    <h1> Fisa de Inscriere </h1>
                    <h6> <a href = "http://admitere.unefs.ro" target = "_blank"> Link catre Brosurile de Admitere </a></h6>
                    <Divider />


                    <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.rural} onChange = {(value, checked) => this.setState({rural : checked, dizabilitati: false, social : false, rrom: false })}> Aplic pe locurile alocate absolventilor din mediul rural </Checkbox>
                    </div>

                    <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.social} onChange = {(value, checked) => this.setState({social : checked, dizabilitati:false, rural : false, rrom : false })}> Aplic pe locurile alocate absolventilor de protectie sociala </Checkbox>
                    </div>

                    <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.rrom} onChange = {(value, checked) => this.setState({rrom : checked, dizabilitati: false, rural : false, social : false})}> Aplic pe locurile alocate absolventilor de etnie rroma (locuri disponibile doar la Programele de Studii Educatie Fizica si Sport, Sport si Performanta Motrica si Kinetoterapie si Motricitate Speciala) </Checkbox>
                    </div>

                    <div className = "Input-element" >

                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.dizabilitati} onChange = {(value, checked) => this.setState({dizabilitati : checked, rrom: false, rural : false, social : false})}> Aplic pe locurile alocate absolventilor cu dizabilitati </Checkbox>
                    </div>


                    <div className = "Input-element" >
                        <p> Facultatea </p>
                        <SelectPicker value= {this.state.facultatea} onSelect = {this.handleSelectFacultate} data = {title} id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>

                    {this.state.facultatea !== null? <div style = {{display: "flex", flexDirection:"row", justifyContent: "space-between"}}>
                        <div>
                            <p> Program de Studii</p>
                            <CheckPicker value= {this.state.optionList} id = "hello" searchable = {false} onClean = {() => this.setState({optionList: []})} onSelect = {this.selectOption} data={data} style={{ width: 400}} />
                        </div>
                        <div>
                            <p> Lista optiuni in ordinea preferintei </p>
                            {options}
                        </div>
                    </div>:null}


                    {/* <div className = "Input-element" >
                        <Checkbox checked= {this.state.ambeleFac} style = {{fontSize: "17px"}} onChange = {this.selectAmbele}> Aplic la ambele facultati </Checkbox>
                    </div> */}


                    {this.state.ambeleFac === true?<div className = "Input-element" >
                        <p> Facultatea a doua </p>
                        <SelectPicker value= {this.state.facultatea2} onClean = {() => this.setState({facultatea2: ""})} onSelect = {(value) => this.setState({facultatea2 : value})} data = { this.state.facultatea === "Kinetoterapie"? [{label: "Educatie Fizica si Sport", value : "Educatie Fizica si Sport"}]:[{label: "Kinetoterapie", value : "Kinetoterapie"}]}
                        id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>:null}
                    
                  

                   

                    {this.state.facultatea2 !== null && this.state.ambeleFac === true? <div style = {{display: "flex", flexDirection:"row", justifyContent: "space-between", marginTop: "40px"}}>
                        <div>
                            <p> Program de Studii - Faculatea a doua </p>
                            <CheckPicker value= {this.state.optionList} id = "hello" searchable = {false} onClean = {() => this.setState({optionList: []})} onSelect = {this.selectOption} data={data2} style={{ width: 400}} />
                        </div>
                        {/* <div>
                            <p> Lista optiuni in ordinea preferintei </p>
                            {options2}
                        </div> */}
                    </div>:null}
                    
                   
                   
                    {/* {this.state.facultatea !== null?<div className = "Input-element" >
                        <p> Program de Studii</p>


                        <SelectPicker
                        onSelect = {this.handleSelectProba} 
                        data = {(this.state.facultatea === "Kinetoterapie")?[
                            {label: "Kinetoterapie si motricitate speciala", value : "Kinetoterapie si motricitate speciala"},
                    
                        ]: []}
                        id = "input"  block searchable = {false} placeholder = "Alege o Optiune"/> 
                    </div>: null} */}

                    <div className = "Input-element" >
                        <p> Sesiunea </p>
                        <SelectPicker value= {this.state.sesiunea} onSelect = {this.handleSelectSesiune} data = {[{label: "Septembrie 2024", value : "Septembrie 2024"}]} id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>

                    <div className = "Input-element" >
                        <p> Initiala Tatalui </p>
                        <Input value= {this.state.initiala} onChange = {(value) =>  (value.length <=5)?this.setState({initiala : value}):null} placeholder = "Exemplu: A.B" name = "initiala" className = "input" />
                    </div>

                    <div className = "Input-element" >
                        <p> Numele din inainte de casatorie (daca este cazul) </p>
                        <Input value= {this.state.casatorie} onChange = {this.handleChange} name = "casatorie" className = "input" />
                    </div>
                   
                    <div className = "Input-element" >
                        <p> Localitatea </p>
                        <Input value= {this.state.localitatea} onChange = {this.handleChange} name = "localitatea" className = "input" />
                    </div>
                    
                    <div className = "Input-element" >
                        <p> Cetatenie </p>
                        <Input value= {this.state.cetatenie} onChange = {this.handleChange} name = "cetatenie" className = "input" />
                    </div>

                    <div className = "Input-element" >
                        <p> Adresa Domiciliu </p>
                        <Input value= {this.state.domiciliu} onChange = {this.handleChange} name = "domiciliu" className = "input"/>
                    </div>

                    {/* <div className = "Input-element" >
                        <p> Telefon Fix </p>
                        <Input onChange = {this.handleChange} name = "telefonFix" className = "input"/>
                    </div> */}

                    <div className = "Input-element" >
                        <p> Telefon Mobil </p>
                        <Input value= {this.state.telefonMobil} onChange = {this.handleChange} name = "telefonMobil" className = "input"/>
                    </div>


                    <div className = "Input-element" >
                        <p>Nota obținută la examenul de bacalaureat  </p>
                        <InputNumber value= {this.state.medieBAC} onChange = {this.handleChange} name = "medieBAC" className = "input"  step={0.5} min = {6} max = {10}/>
                        {/* <Input onChange = {this.handleChange} name = "medieBAC" className = "input"/> */}

                    </div>

                    {(this.state.optionList.includes("Educatie Fizica si Sportiva") === true || this.state.optionList.includes("Sport si Performanta Motrica") === true) && this.state.optionList.includes("IFR") === false && 
                    this.state.optionList.includes("examen") === false?

                    <div className = "Input-element" >
                        <p>Algere proba sportiva  </p>
                        <SelectPicker
                        onSelect = {this.handleSelectProba} 
                        data = {[
                            {label: "1. Baschet", value : "1. Baschet"},
                            {label: "2. Volei", value : "2. Volei"},
                            {label: "3. Handbal", value : "3. Handbal"},
                            {label: "4. Fotbal", value : "4. Fotbal"},
                            {label: "5. Gimnastica - Saritura Departat peste Capra", value : "5. Gimnastica - Saritura Departat peste Capra"},
                            {label: "6. Gimnastica - Saritura in Sprijin Ghemuit pe Lada de Gimnastica", value : "6. Gimnastica - Saritura in Sprijin Ghemuit pe Lada de Gimnastica"},
                            {label: "7. Atletism - Saritura in Lungime cu Elan", value : "7. Atletism - Saritura in Lungime cu Elan"},
                            {label: "8. Atletism - Aruncare Greutatii", value : "8. Atletism - Aruncare Greutatii"},
                            {label: "9. Atletism - Alergarea de Rezistenta", value : "9. Atletism - Alergarea de Rezistenta"},
                            {label: "10. Discipline de Combat (Doar pentru FEFS)", value : "10. Discipline de Combat (Doar pentru FEFS)"},
                        ]}
                        id = "input"  block searchable = {false} placeholder = "Alege o Optiune"/> 
                    </div>:null}

                    <div className = "Input-element" >
                        <p>Medie la disciplina Educatie Fizica si Sport / Pregatire sportiva practica (in ultimul an de studiu in care a fost prevazuta în planul cadru din ciclul liceal.) </p>
                        <InputNumber value= {this.state.medieEdFizica} onChange = {this.handleChange} name = "medieEdFizica" className = "input" defaultValue={5} step={0.5} min = {5} max = {10}/>
                        {/* <Input onChange = {this.handleChange} name = "medieEdFizica" className = "input"/> */}
                    </div>

                    <div className = "Input-element" >
                        <p>Nota la Examenul de Bacalaureat la proba de Limba si Literatura Romana  </p>
                        <InputNumber value= {this.state.medieBACRO} onChange = {this.handleChange} name = "medieBACRO" className = "input" defaultValue={5} step={0.5} min = {5} max = {10}/>
                        {/* <Input onChange = {this.handleChange} name = "medieBACRO" className = "input"/> */}
                    </div>

                    <div className = "Input-element" >
                        <p> Studii Universitare </p>
                        <SelectPicker value= {this.state.studii_univ} onSelect = {(value) => this.setState({studii_univ : value})} 
                        data = {[{label: "Nu am mai fost inscris la alta facultate", value : "Nu am mai fost inscris la alta facultate"}, 
                        {label: "Am fost inscris la alta facultate, dar nu am absolvit", value : "Am fost inscris la alta facultate, dar nu am absolvit"},
                        {label: "Am absolvit alta facultate", value : "Am absolvit alta facultate"}
                    ]} id = "input" block searchable = {false} placeholder = "Alege o Optiune" />
                    </div>

                    {this.state.studii_univ !== "Nu am mai fost inscris la alta facultate" && this.state.studii_univ !== "" ?
                        <div className = "Input-element" >
                            <p>Va rugam sa mentionati forma de finantare (BUGET /TAXA) si cati ani de studiu ati beneficiat de locuri de la bugetul de stat</p>
                            <Input value= {this.state.detalii_studii_univ}  name = "detaliiUniv" className = "input" onChange = {(value) => this.setState({detalii_studii_univ : value})}></Input>
                        </div>:null
                    } 


                    { (this.state.optionList.length === 1 && this.state.optionList.includes("Sport si Performanta Motrica - IFR") === true)? <p>Candidații admisi la programul Sport si performanta motrica - Invatamant cu frecventa redusa, NU POT URMA CURSURILE PROGRAMULUI DE FORMARE PSIHOPEDAGOGICA</p>:
                    <div className = "Input-element" >
                        <Checkbox checked= {this.state.psiho} style = {{fontSize: "17px"}} defaultChecked onChange = {(value, checked) => this.setState({psiho : checked })}> Doresc inscrierea la modulul de Formare Psihopedagogica </Checkbox>
                    </div>}
                    {/* {this.state.studii_univ !== "Nu am mai fost inscris la alta facultate"?
                        <div className = "Input-element" >
                            <Checkbox style = {{fontSize: "17px"}} checked = {this.state.confirm} onChange = {(value, checked) => this.setState({confirm : checked})}> Declar pe proprie raspundere faptul ca am urmat cursurile in regim de taxa/ buget </Checkbox>
                        </div>:null
                    }  */}

                    <div className = "Input-element" >
                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.declar} onChange = {(value, checked) => this.setState({declar : checked})}>DECLAR PE PROPRIA RASPUNDERE SI SEMNEZ PENTRU CONFORMITATE, CA CELE MENTIONATE CORESPUND REALITATII.</Checkbox>
                    </div>

                    <div className = "Input-element" >
                        <Checkbox style = {{fontSize: "17px"}} checked = {this.state.gdpr} onChange = {(value, checked) => this.setState({gdpr : checked})}>SUNT DE ACORD CU <a href = "http://admitere.unefs.ro/wp-content/uploads/2022/03/FEFS-ANEXA-2a-GDPR.pdf">POLITICA DE CONFIDENTIALITATE IMPUSA DE UNEFS</a></Checkbox>
                    </div>

                   

                    <Button onClick = {() =>  window.location.reload()} appearance = "ghost" > Inapoi </Button>

                    <Button onClick = {this.sendData} appearance = "primary" > Salveaza si continua</Button>
                </div>
            </div>
        )
    }
}

export default Signup;