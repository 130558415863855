import React from 'react'
import './stylesheets/Documents.scss'
import {Divider, Uploader, Button, Slider } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'

class Documents extends React.Component{

    state = {
        file_poza : null,
        file_certificatNastere : null,
        file_carteIdentitate : null,
        file_foaieMatricola : null,
        file_certificatCasatorie : null,
        file_adeverintaBac : null,
        file_acordSIR : null,
        file_dovada : null,


        file_cerereInscriere : null,
        file_cerereInscriere2 : null,

        file_gdpr : null,
        file_gdpr2 : null,
        file_consultatii : null,
        file_bac : null,
        file_bac_2 : null,
        file_douaFacultati : null,
        file_rrom1 : null,
        file_rrom2 : null,
        file_rrom3 : null,
        file_sport1 : null,
        file_sport2 : null,
        file_limba : null,
        file_psiho : null,
        file_licenta2 : null,
        file_licenta : null,
        file_supliment : null,
        file_eseu : null,
        nr_documents : "",
        loading : false,
        showModal : false,
        image: 'your-image-url or as base64',
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 3 / 4,
        rotation: 0,
        base64data : "",
        croppedArea : "",
        firstLoad : true,
        subInfo : ""
    }

    async componentDidMount(){

        let userPromise = await axios.get('/api/user/getUserInfo/' + localStorage.getItem('id'));

        this.setState({
            firstLoad : (userPromise.has_submited_documents === true)?false:true
        })

        let submissionData = await axios.get('/api/submission/getInfo/' + localStorage.getItem('id'));

        console.log(submissionData.data.optionList)
        this.setState({
            subInfo : submissionData.data
        })

    }

    onCropChange = crop => {
        this.setState({ crop })
      }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea)
        console.log(croppedAreaPixels)
        this.setState({
            croppedArea : croppedAreaPixels
        })
      }

    showCroppedImage = async () => {

        try {
          const croppedImage = await getCroppedImg(
            this.state.base64data,
            this.state.croppedArea,
            this.state.rotation
          )
          console.log('donee', { croppedImage })
          this.setState({file_poza : croppedImage.croppedImage })
        } catch (e) {
          console.error(e)
        }

      }

    
      onZoomChange = zoom => {
        this.setState({ zoom })
      }

      setRotation = rotation => {
        this.setState({ rotation })
      }
    handlePoza = async (fileList) => {
    
        console.log(fileList[0].blobFile);

        var reader = new FileReader();
        reader.readAsDataURL(fileList[0].blobFile); 
        var base64data = null;
        reader.onloadend =  () => {
            base64data = reader.result;                
            console.log(base64data)
            this.setState({
                file_poza : fileList[0].blobFile,
                base64data : base64data
            }, () => {
                // this.toggleModal();
            })
        }

        
    }

    handlePsiho = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_psiho : fileList[0].blobFile
        })

    } 

    handleLicenta = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_licenta : fileList[0].blobFile
        })
        if( fileList.length > 1)
        this.setState({
            file_licenta2 : fileList[1].blobFile
        })

    }

    handleSupliment = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_supliment : fileList[0].blobFile
        })

    }

    handleEseu = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_eseu : fileList[0].blobFile
        })

    }

    handleAcordSIR = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_acordSIR : fileList[0].blobFile
        })

    }

    handleAdeverintaBac = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_adeverintaBac : fileList[0].blobFile
        })

    }

    handleFoaieMatricola = (fileList) => {

        if( fileList.length > 0)
        this.setState({
            file_foaieMatricola : fileList[0].blobFile
        })

    }
    handleLimba = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_limba : fileList[0].blobFile
        })
    }

    handleBAC = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_bac : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_bac_2 : fileList[1].blobFile
        })
    }

    handleCarteIdentitate = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_carteIdentitate : fileList[0].blobFile
        })
    }

    handleCasatorie = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_certificatCasatorie : fileList[0].blobFile
        })
    }

    handleCertificatNastere = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_certificatNastere : fileList[0].blobFile
        })
    }

    handleInscriere2 = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_cerereInscriere2 : fileList[0].blobFile
        })
    }

    handleFacultate = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_douaFacultati : fileList[0].blobFile
        })
    }
    handleDovada = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_dovada : fileList[0].blobFile
        })
    } 

    handleFisaMedicala = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_consultatii : fileList[0].blobFile
        })
    }

    handleGDPR = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_gdpr : fileList[0].blobFile
        })

        if( fileList.length > 1)
        this.setState({
            file_gdpr2 : fileList[1].blobFile
        })
    }


    handleInscriere = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_cerereInscriere : fileList[0].blobFile
        })
    }

    handleRrom1 = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_rrom1 : fileList[0].blobFile
        })
    }

    handleRrom2 = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_rrom2 : fileList[0].blobFile
        })
    }

    handleRrom3 = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_rrom3 : fileList[0].blobFile
        })
    }


    handleSport1 = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_sport1 : fileList[0].blobFile
        })
    }

    handleSport2 = (fileList) => {
    
        if( fileList.length > 0)
        this.setState({
            file_sport2 : fileList[0].blobFile
        })
    }


    toggleModal = (name) => {

        let current = this.state.showModal;

        this.setState({
            showModal : !current,
        })
    }

    sendData = () => {

        if ( this.state.firstLoad === true ){
            // if ( this.state.file_dovada === null )
            // {
            //     toast.error("Nu ati adaugat Dovada de Plata")
            //     return;
            // }
            // if ( this.state.file_certificatNastere === null )
            // {
            //     toast.error("Nu ati adaugat Certificatul de Nastere")
            //     return;
            // }
            // if ( this.state.file_carteIdentitate === null )
            // {
            //     toast.error("Nu ati adaugat Copie dupa CI")
            //     return;
            // }
            // if ( this.state.file_poza === null )
            // {
            //     toast.error("Nu ati adaugat Poza de Legitimatie")
            //     return;
            // }

            // if ( this.state.subInfo.psiho === true ){
            //     if ( this.state.file_psiho === null )
            //     {
            //         toast.error("Nu ati adaugat Certificatul de absolvire a programului de formare psihopedagogică, nivel I")
            //         return;
            //     }
            // }

            // if ( this.state.subInfo.ambeleFac === true ){
            //     if ( this.state.file_cerereInscriere2 === null )
            //     {
            //         toast.error("Nu ati adaugat Cererea de Inscriere pentru facultatea a doua")
            //         return;
            //     }
            // }
            // if ( this.state.file_cerereInscriere === null )
            // {
            //     toast.error("Nu ati adaugat Cererea de Inscriere")
            //     return;
            // // }
            // if ( this.state.file_gdpr === null )
            // {
            //     toast.error("Nu ati adaugat consimțământului în vederea prelucrării datelor cu caracter personal")
            //     return;
            // }
            // // if ( this.state.file_consultatii === null )
            // {
            //     toast.error("Nu ati adaugat fisa de consultatii medicale ")
            //     return;
            // }
            // if ( this.state.file_licenta === null )
            // {
            //     toast.error("Nu ati adaugat Copie dupa Diploma de Licenta")
            //     return;
            // }

            // if ( this.state.file_foaieMatricola === null )
            // {
            //     toast.error("Nu ati adaugat Foaia Matricola")
            //     return;
            // }

            // if ( this.state.subInfo.ambeleFac === true ){
            //     if ( this.state.file_cerereInscriere2 === null )
            //     {
            //         toast.error("Nu ati adaugat Adeverinta pentru doua facultati simultan")
            //         return;
            //     }
            // }

            // if ( this.state.subInfo.rrom === true ){
            //     if ( this.state.file_rrom1 === null )
            //     {
            //         toast.error("Nu ati adaugat Recomandarea scrisă din partea preşedintelui unei organizaţii legal constituite a rromilor ")
            //         return;
            //     }
            //     if ( this.state.file_rrom2 === null )
            //     {
            //         toast.error("Nu ati adaugat Copie legalizată (sau conform cu originalul) a hotărârii judecătoreşti care atestă constituirea legală a organizaţiei respective")
            //         return;
            //     }
            //     if ( this.state.file_rrom3 === null )
            //     {
            //         toast.error("Nu ati adaugat Declaraţie pe proprie răspundere că respectivul candidat face parte din etnia rromă")
            //         return;
            //     }
            // }
            
            // if ( this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Zi - Engleza") === true ){
            //     if ( this.state.file_limba === null )
            //     {
            //         toast.error("Nu ati adaugat Atestatul de limba")
            //         return;
            //     }

            // }

            // if ( this.state.subInfo.optionList.includes("Sport si Performanta Motrica - IFR") === true ){
            //     if ( this.state.file_sport1 === null )
            //     {
            //         toast.error("Nu ati adaugat Adeverinţă eliberată de federaţia de specialitate")
            //         return;
            //     }
            //     if ( this.state.file_sport2 === null )
            //     {
            //         toast.error("Nu ati adaugat copie legalizată sau conform cu originalul după diploma obţinută")
            //         return;
            //     }
            // }
        }
        

        const fd = new FormData();
        console.log(this.state.file_fisaMedicala)

        if ( this.state.file_psiho !== null )
        {
            fd.append('diploma_nivel1', this.state.file_psiho, this.state.file_psiho.name);
        }

        if ( this.state.file_dovada !== null )
        {
            fd.append('dovada_plata', this.state.file_dovada, this.state.file_dovada.name);
        }
        
        if ( this.state.file_licenta !== null )
        {
            fd.append('diploma_licenta', this.state.file_licenta, this.state.file_licenta.name);
        }
        if ( this.state.file_licenta2 !== null )
        {
            fd.append('diploma_licenta_page2', this.state.file_licenta2, this.state.file_licenta2.name);
        }
        if ( this.state.file_supliment !== null )
        {
            fd.append('supliment_diploma', this.state.file_supliment, this.state.file_supliment.name);
        }
        if ( this.state.file_eseu !== null )
        {
            fd.append('eseu', this.state.file_eseu, this.state.file_eseu.name);
        }


        if ( this.state.file_acordSIR !== null )
        {
            fd.append('acord_siiir', this.state.file_acordSIR, this.state.file_acordSIR.name);
        }

        if ( this.state.file_adeverintaBac !== null )
        {
            fd.append('adeverinta_bac', this.state.file_adeverintaBac, this.state.file_adeverintaBac.name);
        }

        if ( this.state.file_foaieMatricola !== null )
        {
            fd.append('foaie_matricola', this.state.file_foaieMatricola, this.state.file_foaieMatricola.name);
        }

        if ( this.state.file_bac !== null )
        {
            fd.append('document_bac', this.state.file_bac, this.state.file_bac.name);
        }

        if ( this.state.file_bac_2 !== null )
        {
            fd.append('document_bac_2', this.state.file_bac_2, this.state.file_bac_2.name);
        }
        if ( this.state.file_certificatCasatorie !== null )
        {
            fd.append('certificat_casatorie', this.state.file_certificatCasatorie, this.state.file_certificatCasatorie.name);
        }
        if ( this.state.file_carteIdentitate !== null )
        {
            fd.append('carte_identitate', this.state.file_carteIdentitate, this.state.file_carteIdentitate.name);
        }
        if ( this.state.file_cerereInscriere !== null )
        {      
            fd.append('cerere_inscriere', this.state.file_cerereInscriere, this.state.file_cerereInscriere.name);
        }

        if ( this.state.file_cerereInscriere2 !== null )
        {      
            fd.append('cerere_inscriere2', this.state.file_cerereInscriere, this.state.file_cerereInscriere.name);
        }
        if ( this.state.file_poza !== null )
        {      
            fd.append('poza_legitimatie', this.state.file_poza, 'test.png');
        }
        if ( this.state.file_certificatNastere !== null )
        {      
            fd.append('certificat_nastere', this.state.file_certificatNastere, this.state.file_certificatNastere.name);
        }
        if ( this.state.file_consultatii !== null )
        {      
            fd.append('fisa_consultatii', this.state.file_consultatii, this.state.file_consultatii.name);
        }
        if ( this.state.file_douaFacultati !== null )
        {      
            fd.append('adeverinta_finantare', this.state.file_douaFacultati, this.state.file_douaFacultati.name);
        }
        if ( this.state.file_gdpr !== null )
        {      
            fd.append('document_gdpr', this.state.file_gdpr, this.state.file_gdpr.name);
        }

        if ( this.state.file_gdpr2 !== null )
        {      
            fd.append('document_gdpr_2', this.state.file_gdpr2, this.state.file_gdpr2.name);
        }
        
        if ( this.state.file_rrom1 !== null )
        {      
            fd.append('recomandare_scrisa_r1', this.state.file_rrom1, this.state.file_rrom1.name);
        }
        if ( this.state.file_rrom2 !== null )
        {      
            fd.append('copie_legalizata_r2', this.state.file_rrom2, this.state.file_rrom2.name);
        }
        if ( this.state.file_rrom3 !== null )
        {      
            fd.append('declaratie_raspundere_r3', this.state.file_rrom3 , this.state.file_rrom3.name);
        }
        if ( this.state.file_sport1 !== null )
        {      
            fd.append('adeverinta_federatie_s1', this.state.file_sport1 , this.state.file_sport1.name);
        }
        if ( this.state.file_sport2 !== null )
        {      
            fd.append('diploma_competitie_s2', this.state.file_sport2 , this.state.file_sport2.name);
        }
        if ( this.state.file_limba !== null )
        {      
            fd.append('atestat_limba', this.state.file_limba , this.state.file_limba.name);
        }

       
    
        this.setState({
            loading : true
        })

        axios.post('/api/submission/submitDocuments/' + localStorage.getItem('id'), fd)
        .then(response => {

            console.log(response)

            axios.get('/api/submission/getNrDocuments/' + localStorage.getItem('id'))
            .then( response => {
                console.log(response);

                this.setState({
                    nr_documents : response.data,
                    loading : false
                })

                if ( response.data >= 1 ){
                    toast.success("Incarcare cu succes!")
                    window.location.reload();
                }
                else{
                    window.location.reload();
                    toast.error("Eroare la incarcare")
                }
            })
        })

    }

    render(){

        const styles = {
            lineHeight: '200px'
          };
          if ( this.state.loading === true) 
            return  <p> Se incarca, va rugam asteptati. Nu inchideti pagina! </p>

        if ( this.state.subInfo === "")
            return  <p> Se incarca, va rugam asteptati. Nu inchideti pagina! </p>

        return(
            <div className = "Documents-container">
                <div className = "Documents-content">

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'onedocument-modal',
                        closeButton: 'modal-close-button',
                        }} open={this.state.showModal} onClose={this.toggleModal} center>
                            <p style = {{marginTop: "20x"}}> Va rugam taiati fotografia astfel incat sa fie vizibila fata dumneavoastra </p>
                            <div className="crop-container">
                                <Cropper
                                    image={this.state.base64data}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    rotation = {this.state.rotation}
                                    aspect={this.state.aspect}
                                    onZoomChange = {this.onZoomChange}
                                    onCropChange={this.onCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onRotationChange={this.setRotation}
                                />
                            </div>
                            <p> Rotatie </p>
                            <Slider
                                step = {90}
                                max = {360}
                                min = {0}
                                progress 
                                graduated
                                // value = {this.state.rotation}
                                defaultValue={0}
                                onChange={value => {
                                    this.setRotation(value)
                                }}
                            />
                            <p style = {{marginTop: "30px"}}> Zoom </p>
                            <Slider
                                min = {1}
                                max = {5}
                                progress 
                                graduated
                                // value = {this.state.zoom}
                                defaultValue={1}
                                onChange={value => {
                                    this.onZoomChange(value)
                                }}
                            />
                            <Button onClick = {this.showCroppedImage} appearance = "primary" style = {{marginTop: "30px"}}>
                                Accept Fotografie
                            </Button>
                    </Modal>





                    <h1> Documente de inscriere - Masterat </h1>
                    <h6> <a href = "http://admitere.unefs.ro" target = "_blank"> Link catre Brosurile de Admitere </a></h6>
                    <h6> <strong>!IMPORTANT:</strong> Rugam canditatii sa incarce aceste documente de pe calculator/laptop </h6>

                    {this.state.firstLoad === false?<p>Sunteti in modul de re-upload. Nu trebuie sa urcati toate documentele. Va rugam uploadati doar documentul/documentele refuzate de catre comisie si apoi apasati pe butonul abastru de "Adauga documente" din josul paginii</p>:null}
                    
                    <Divider />

                    {/* <div className = "Upload-zone">
                        <h3> Poza legitimatie </h3>
                        <p> Fisiere acceptate: .jpg, Dimensiune tip buletin de identitate, maximum 5MB</p>
                        <Uploader onChange = {this.handlePoza} accept = ".jpg" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    <div className = "Upload-zone">
                        <h3> Certificat de Nastere </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleCertificatNastere} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>
                    
                    {/* <div className = "Upload-zone">
                        <h3> Foaia Matricola clasele IX-XII
                            Adeverinta eliberata de catre institutia de invatamant din care sa reiasa nota obtinuta la disciplina Educatie Fizica si Sport/ Pregatire sportiva practica   </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleFoaieMatricola} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    {/* <div className = "Upload-zone">
                        <h3> Adeverinta eliberata de catre institutia de invatamant din care sa reiasa nota obtinuta la examenul de bacalaureat, doar pentru absolventii din promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleAdeverintaBac} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    {/* <div className = "Upload-zone">
                        <h3> Acord privind posibilitatea verificarii in Sistemul informatic integrat al invatamantului din Romania (SIIIR) a notei obtinute la examenul de bacalaureat, doar pentru promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleAcordSIR} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    <div className = "Upload-zone">
                        <h3> Cartea de Identitate </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleCarteIdentitate} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    <div className = "Upload-zone">
                        <h3> Certificat de Casatorie (daca este cazul)</h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleCasatorie} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    {/* <div className = "Upload-zone">
                        <h3> Cerere de Inscriere (<a href = "https://unefsb.ro/wp-content/uploads/2020/07/Fisa-inscriere-MASTER.pdf" target = "_blank">descarca aici</a>) </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleInscriere} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    {/* {this.state.subInfo.ambeleFac === true? 
                        <div className = "Upload-zone">
                            <h3>  Cerere de inscriere pentru a doua facultate </h3>
                            <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                            <Uploader onChange = {this.handleInscriere2} accept = ".pdf" autoUpload = {false} dragable>
                                <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                            </Uploader>
                        </div>
                    : null} */}

                    <div className = "Upload-zone">
                        <h3> Declaratie privind acordarea consimțământului în vederea prelucrării datelor cu caracter personal (<a href = "http://admitere.unefs.ro/wp-content/uploads/2022/03/FEFS-ANEXA-2a-GDPR.pdf" target = "_blank">descarca aici</a>)</h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleGDPR} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    <div className = "Upload-zone">
                        <h3> Dovada platii taxei de înscriere/ Dovada scutirii de la plata taxei </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB </p>
                        <Uploader onChange = {this.handleDovada} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    {/* <div className = "Upload-zone">
                        <h3> ANEXA 1- Fisa de consultatii medicale ( Pagina 1, punctele A- Datele de identificare si B Rezultatele examinarii medicului de familie) </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleFisaMedicala} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}

                    <div className = "Upload-zone">
                        <h3> Diploma de bacalaureat (copie fata-verso). Recunoasterea si echivalarea actelor de studii de nivel preuniversitar obtinute in strainatate, de catre CNRED, daca este cazul. </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleBAC} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    <div className = "Upload-zone">
                        <h3> Diploma de Licenta pentru absolventii promotiilor anterioare anului universitar 2021-2022, 
                            <br/>sau adeverinta pentru canditatii care au promovat examenul de licență în promotia 2023, 
                            <br/>sau adeverinta pentru absolventii altor promoții, inscrisi la alte programe de master, la alte facultăți, însoțite de o adeverință eliberată de facultatea / programul de studiu unde s-a depus dosarul cu acte originale, cu precizarea mediei pentru fiecare an de studiu și a mediei de absolvire a examenului de licență </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleLicenta} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    <div className = "Upload-zone">
                        <h3> Diploma supliment sau adeverinta </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleSupliment} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>


                     <div className = "Upload-zone">
                        <h3> Eseu Tematic </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleEseu} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div>

                    {/* <div className = "Upload-zone">
                        <h3> Adeverinta eliberata de către institutia de invatamant din care sa reiasa nota obtinuta la examenul de bacalaureat, doar pentru absolventii din promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleBAC} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}
                    
                    {/* <div className = "Upload-zone">
                        <h3> Acord privind posibilitatea verificarii în Sistemul informatic integrat al invatamantului din Romania (SIIIR) a notei obtinute la examenul de bacalaureat, doar pentru promotia 2020 </h3>
                        <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                        <Uploader onChange = {this.handleBAC} accept = ".pdf" autoUpload = {false} dragable>
                            <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                        </Uploader>
                    </div> */}
                               
                    {/* Adeverinta ambele facultati */}
                    {this.state.subInfo.psiho === true? 
                        <div className = "Upload-zone">
                            <h3> Certificatul de absolvire a programului de formare psihopedagogică, nivel I</h3>
                            <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                            <Uploader onChange = {this.handlePsiho} accept = ".pdf" autoUpload = {false} dragable>
                                <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                            </Uploader>
                        </div>
                    : null}

                    {this.state.subInfo.studii_univ !== "Nu am mai fost inscris la alt masterat"? 
                        <div className = "Upload-zone">
                            <h3> Adeverințe de la Facultățile organizatoare pentru cei care urmează sau au absolvit unul sau mai multe programe de Master, din care să reiasă dacă programele au fost urmate în regim de finanțare buget sau cu taxă </h3>
                            <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                            <Uploader onChange = {this.handleFacultate} accept = ".pdf" autoUpload = {false} dragable>
                                <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                            </Uploader>
                        </div>
                    : null}

                    {this.state.subInfo.rrom === true? 
                        <div>
                            <div className = "Upload-zone">
                                <h3> Recomandarea scrisă din partea preşedintelui unei organizaţii legal constituite a rromilor din care să rezulte că respectivul candidat face parte din această etnie </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleRrom1} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
                            <div className = "Upload-zone">
                                <h3> Copie legalizată (sau conform cu originalul) a hotărârii judecătoreşti care atestă constituirea legală a organizaţiei respective </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleRrom2} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
                            <div className = "Upload-zone">
                                <h3> Declaratie pe proprie raspundere ca respectivul candidat face parte din etnia rroma </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleRrom3} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
                        </div>

                    : null}

                {/* {this.state.subInfo.optionList.includes("Sport si Performanta Motrica - IFR") === true? 
                        <div>
                            <div className = "Upload-zone">
                                <h3> Adeverinţă eliberată de federaţia de specialitate </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleSport1} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
                            <div className = "Upload-zone">
                                <h3> Canditatii care au obtinut locurile I – III si mentiune la Olimpiada Nationala de Pregatire Teoretica Sportiva vor prezenta în copie legalizata sau conform cu originalul după diploma obtinuta. </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleSport2} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
                          
                        </div>

                    : null} */}

                    {/* {this.state.subInfo.optionList.includes("Sport si Performanta Motrica - Zi - Engleza") === true? 
                        <div>
                            <div className = "Upload-zone">
                                <h3> Atestat Cambridge/TOEFL </h3>
                                <p> Fisiere acceptate: .pdf, maximum 5MB</p>
                                <Uploader onChange = {this.handleLimba} accept = ".pdf" autoUpload = {false} dragable>
                                    <div style={styles}>Apasa sau Adauga fisierele aici pentru upload </div>
                                </Uploader>
                            </div>
    
                          
                        </div>

                    : null} */}

                    {/*  daca e rrom 1) Recomandarea scrisă din partea preşedintelui unei organizaţii legal constituite a rromilor din care să rezulte că respectivul candidat face parte din această etnie
                                    2) Copie legalizată (sau conform cu originalul) a hotărârii judecătoreşti care atestă constituirea legală a organizaţiei respective
                                    3) Declaraţie pe proprie răspundere că respectivul candidat face parte din etnia rromă;
                    
                    */}
                    <p> In caz de probleme tehnice, va rugam sa ne contactacti la admitere_fefs@unefs.ro/admitere_fkt@unefs.ro </p>

                    <Button onClick = {this.sendData} appearance = "primary" > Adauga documentele </Button>
                    <Button onClick = {() => window.location.reload()}appearance = "ghost" > Inapoi</Button>

                </div>
            </div>

        )
    }
}

export default Documents;